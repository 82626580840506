import React from "react";
import PropTypes from "prop-types";
import { kebabCase } from "lodash";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import useSiteMetadata from "../components/SiteMetadata";
import * as ContentComponents from "../components/ContentComponents";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

import logoSolidWhite from "../img/signature-solid-white.svg";

export const BlogPostTemplate = ({
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <article className="section blog-post">
      {helmet || ""}

      <PostContent content={content} className="prose lg:prose-lg max-w-none" />

      {
        // NOTE: Hiding for now
      }
      {false && tags && tags.length ? (
        <div className="mt-8">
          <h4>Tags</h4>
          <ul className="taglist">
            {tags.map((tag) => (
              <li key={tag + `tag`}>
                <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
              </li>
            ))}
          </ul>
        </div>
      ) : null}
    </article>
  );
};

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const BlogPost = ({ data }) => {
  const { markdownRemark: post } = data;
  const { siteUrl } = useSiteMetadata();

  return (
    <Layout
      headerClassName="bg-navy-p1 curve-br"
      //headerContent={<span />}
      navbarLogo={logoSolidWhite}
      className="accent-fuchsia"
      headerTitle={
        <div
          className={`columns pb-2 md:pb-4 pt-0 lg:pt-4 ${
            post.frontmatter.featuredimage ? "md:pb-24" : "md:pb-10"
          }`}
        >
          <div className="w-full md:w-10/12 lg:w-9/12">
            <span className="text-sm md:text-md lg:text-lg font-medium text-white">
              {post.frontmatter.date}
              {post.frontmatter.author && ` - ${post.frontmatter.author}`}
            </span>
            <h1 className="font-heading mt-1 text-white text-2xl md:text-3xl lg:text-4xl pr-16 md:pr-0 ">
              {post.frontmatter.title}
            </h1>
          </div>
        </div>
      }
    >
      <Helmet titleTemplate="%s - ScriptDrop">
        <title>{`${post.frontmatter.title}`}</title>
        <meta name="description" content={post.frontmatter.description} />
        <meta property="og:title" content={post.frontmatter.title} />
        <meta property="og:type" content="article" />
        <meta
          property="og:description"
          content={post.frontmatter.description}
        />
        {post.frontmatter.featuredimage && (
          <meta
            property="og:image"
            content={siteUrl.concat(
              post.frontmatter.featuredimage.childImageSharp
                ? post.frontmatter.featuredimage.childImageSharp.shareImage.src
                : post.frontmatter.featuredimage.publicURL
            )}
          />
        )}
        {post.frontmatter.featuredimagealt && (
          <meta
            property="og:image:alt"
            content={post.frontmatter.featuredimagealt}
          />
        )}
      </Helmet>

      <div className="container mt-2 md:mt-8">
        {post.frontmatter.featuredimage ? (
          <div
            style={{ zIndex: 32 }}
            className="columns md:-mt-32 md:relative mb-4 md:mb-8"
          >
            <div className="w-full md:w-11/12 lg:w-10/12">
              <figure className="rounded-lg lg:rounded-xl overflow-hidden">
                <PreviewCompatibleImage
                  imageInfo={{
                    image: post.frontmatter.featuredimage,
                    alt: post.frontmatter.featuredimagealt || "",
                  }}
                />
              </figure>
            </div>
          </div>
        ) : null}

        <div className="columns">
          <div className="w-full md:w-10/12 lg:w-9/12">
            <BlogPostTemplate
              content={post.html}
              contentComponent={
                (post.frontmatter.contentComponent &&
                  ContentComponents[post.frontmatter.contentComponent]) ||
                HTMLContent
              }
              tags={post.frontmatter.tags}
              title={post.frontmatter.title}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "M.D.YY")
        title
        description
        tags
        author
        focuskeyword
        contentComponent
        featuredimagealt
        featuredimage {
          publicURL
          childImageSharp {
            fluid(
              maxWidth: 930
              quality: 75
              traceSVG: { background: "#FBFBFF", color: "#DBE2E8" }
            ) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
            shareImage: resize(width: 1200, height: 630, fit: COVER) {
              src
            }
          }
        }
      }
    }
  }
`;
